<template>
  <div id="home" class="wrap" @click.stop="collapsed()">

    <div class="img-list">
      <img class="item" src="../assets/hippo-image/1.png" alt="">
      <img class="item" src="../assets/hippo-image/2.png" alt="">
      <img class="item" src="../assets/hippo-image/3.png" alt="" id="product">
      <img class="item" src="../assets/hippo-image/4.png" alt="">
      <img class="item" src="../assets/hippo-image/5.png" alt="">
      <div class="footer" id="footer-about-us">
        <div class="left">
          <img src="../assets/images/logo.png" alt="">
        </div>
        <div class="right">
          <div class="item">
            <div class="bottom">
              <div class="name">北京车未科技有限公司</div>
              <div class="desc">
                <div class="icon">
                  <img src="../assets/images/phone.png" alt="">
                </div>
                <div class="text">客服电话：400-000-6025</div>
              </div>
              <div class="desc">
                <div class="icon">
                  <img src="../assets/images/email.png" alt="">
                </div>
                <div class="text">邮箱：business@hemaxiche.com</div>
              </div>
              <div class="desc">
                <div class="icon">
                  <img src="../assets/images/address.png" alt="">
                </div>
                <div class="text">地址：北京市海淀区八里庄街道唐家岭路弘祥1989科技文化产业园2215室</div>
              </div>
            </div>
          </div>
        </div>
        <div class="left line">
          <img src="../assets/images/icon.png" alt="">
        </div>
      </div>
      <div class="footer-text"> ©2017~2022 HIPPOMM.COM ALL RIGHTS RESERVED. <a href="https://beian.miit.gov.cn"
                                                                               target="_blank">京ICP备18008064号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      collapsible: false
    }
  },
  methods: {
    // 跳转到指定位置
    jump(id) {
      window.__forceSmoothScrollPolyfill__ = true
      this.collapsed()
      window.scrollTo({
        top: this.$el.querySelector(id).offsetTop,
        behavior: 'smooth',
        left: 0
      })
      this.collapsed()
    },
    // 拨打电话
    callPhone(phoneNumber) {
      this.collapsed()
      window.location.href = 'tel://' + phoneNumber
    },
    collapsed() {
      this.collapsible = !this.collapsible
    }
  }
}
</script>
<style lang="scss">
.header {
  width: 100%;
  height: 2.7rem;
  background: url('../assets/images/1.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  // padding :0 2rem;
  .phone {
    font-size: .18rem;
    padding-top: .35rem;
    text-align: left;
    width: 2rem;
    padding-left: .1rem;
    font-weight: bold;
    letter-spacing: 2px;
  }
}

.footer-text {
  width: 100%;
  font-size: 30 rpx;
  color: white;
  text-align: center;
  background: #000;
  padding-bottom: 30px;
  margin-top: -10px;
  line-height: 20px;
  padding-left: 40px;
  padding-right: 40px;

  a {

    color: white;
  }
}

.footer {
  width: 100%;
  background: #000;
  display: flex;
  padding: .8rem 0;
  justify-content: space-around;

  .left {
    width: 30%;
    // border-right: 1px solid #ececec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    position: relative;

    img {
      width: 70%;
      height: auto;
      margin: 0 auto;
    }
  }

  .left.line {
    img {
      width: 60%;
    }
  }

  .right {
    text-align: left;
    padding: .1rem 1rem;
    color: white;
    border-left: 1px solid rgba(136, 136, 136, .3);
    border-right: 1px solid rgba(136, 136, 136, .3);

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: .5rem;
        font-weight: bold;
        position: relative;
      }

      .title::after {
        content: '';
        width: 39px;
        height: 3px;
        background-color: #2167e0;
        position: absolute;
        left: 0;
        bottom: -10px;
      }

      .name {
        font-size: .35rem;
      }

      .desc {
        display: flex;
        font-size: 12px;
        line-height: .4rem;
        margin-top: .2rem;

        .icon {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          justify-content: center;

          img {
            width: .4rem;
            height: .4rem;
          }
        }

      }
    }

  }
}

@media screen and (max-width: 736px) {
  .footer {
    display: flex !important;
    flex-direction: column;
    padding: 1rem;

    .left {
      display: flex;
      width: 100%;
      justify-content: center;

      img {
        width: 80%;
        height: auto;
      }
    }

    .right {
      margin: 1rem 0;
      border: none;
    }
  }
}

.wrap {
  width: 100%;

}

.wrap .top-nav {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.wrap .top-nav .logo {
  background-image: url("http://car-washing.oss-cn-hangzhou.aliyuncs.com/hippo-web/ic_logo.png");
  background-size: 142px 22px;
  width: 142px;
  height: 22px;
  background-repeat: no-repeat;
  margin-right: 221px;
}

.wrap .top-nav .nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.wrap {
  .top-nav {
    .nav-menus {
      display: none;
    }
  }
}

@media screen and (max-width: 736px) {
  .wrap {
    .top-nav {
      .logo {
        flex: 1;
        margin: 0 10px;
      }

      .nav {
        display: none;
      }

      .nav-menus {
        flex: 0 0 auto;
        padding-right: 20px;
        display: block;
        position: relative;

        .ellipsis {
          font-size: 24px;
          background-image: url("http://car-washing.oss-cn-hangzhou.aliyuncs.com/hippo-web/ic_menu.png");
          background-size: 24px 24px;
          width: 24px;
          height: 24px;
        }

        .nav-menus-overlay {
          display: flex;
          flex-flow: column;
          position: absolute;
          right: 20px;
          z-index: 1;
          background: #fff;
          border-radius: 10px;
          width: 220px;
          transition: width ease-in-out .5s;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.4);

          .nav-item,
          .contact {
            flex: 1;
            text-align: center;
            font-size: 14px;
            width: 100%;
            font-weight: 400;
            font-family: PingFang SC;
            color: #000000;
            line-height: 22px;
            cursor: pointer;
            padding: 10px 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.wrap .top-nav .nav .nav-item {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin: 0 25px;
  cursor: pointer
}

.wrap .top-nav .contact {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  line-height: 82px;
  margin: 0 25px;
  cursor: pointer
}

.wrap .img-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.posi-phone {
  width: 20%;
  height: 100px;
  // background: red;
  z-index: 999;
  position: absolute;
  right: 5%;
  top: .8%;
}

@media screen and (max-width: 736px) {
  .posi-phone {
    width: 20%;
    height: 25px;
    // background: red;
    z-index: 999;
    position: absolute;
    right: 5%;
    top: .8%;
  }
}

.wrap .img-list .item {
  width: 100%;
}

.wrap .img-list .taste-container {
  background: white;
  width: 100%;
}

.wrap .img-list .taste {
  background-image: url("http://car-washing.oss-cn-hangzhou.aliyuncs.com/hippo-web/ic_qi.png");
  background-size: 228px 230px;
  background-repeat: no-repeat;
  background-position: center top -30px;
  min-height: 740px;
  width: 100%;
}

.taste .title {
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 48px;
  margin-top: 88px;
}

.taste .desc {
  font-size: 30px;
  font-family: PingFang-SC-Regular;
  color: #000000;
  line-height: 30px;
  margin-top: 54px;
}

.taste .list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 165px;
}

.taste .list .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 279px;
  margin: 0 15px;
}

.taste .list .item .top {
  width: 279px;
  height: 185px;
  position: relative;
}

.taste .list .item .bottom {
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 42px 0px;
}

.taste .list .item .red {
  background: linear-gradient(90deg, #C20205, #D04446);
}

.taste .list .item .yellow {
  background: linear-gradient(90deg, #F8CB14, #F9DB5F);
}

.taste .list .item .purple {
  background: linear-gradient(90deg, #930149, #B32E6F);
}

.taste .list .item .cyan {
  background: linear-gradient(90deg, #81CDBB, #9EE7D6);
}

.taste .list .item .top .name {
  font-size: 30px;
  font-family: PingFang-SC-Regular;
  color: #FFFFFF;
  line-height: 30px;
  width: 100%;
  text-align: left;
  padding: 20px 17px;
}

.taste .list .item .top .hippo {
  position: absolute;
  left: 22px;
  bottom: 28px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  opacity: 0.2;
}

.taste .list .item .top .qi {
  position: absolute;
  right: 28px;
  bottom: 28px;
  background-image: url("http://car-washing.oss-cn-hangzhou.aliyuncs.com/hippo-web/ic_qi_mini.png");
  background-size: 69px 69px;
  width: 69px;
  height: 69px;
}

.wrap .about {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.wrap .about .qr-code {
  width: 165px;
  margin-top: 76px;
}

.wrap .about .about-us {
  font-size: 24px;
  font-family: PingFang-SC-Regular;
  font-weight: 500;
  color: #000000;
  margin-top: 22px;
  font-weight: 500;
}

.wrap .about .join-us {
  font-size: 30px;
  font-family: PingFang-SC-Regular;
  color: #000000;
  margin-top: 20px;
}

.wrap .about .phone {
  font-size: 48px;
  font-family: Arial;
  color: #000000;
  margin-top: 100px;
}

.wrap .about .time {
  font-size: 24px;
  font-family: PingFang-SC-Regular;
  color: #666666;
  margin-top: 22px;
  font-weight: 500;
}

.wrap .about .fd {
  font-size: 16px;
  color: #888888;
  margin-top: 30px;
}

.wrap .about .fd > a {
  font-size: 16px;
  color: #888888;
}

@media screen and (max-width: 736px) {
  .wrap .about .about-us {
    font-size: 16px;
    font-family: PingFang-SC-Regular;
    font-weight: 500;
    color: #000000;
    margin-top: 22px;
    font-weight: 500;
  }

  .wrap .about .join-us {
    font-size: 18px;
    font-family: PingFang-SC-Regular;
    color: #000000;
    margin-top: 20px;
  }

  .wrap .about .phone {
    font-size: 28px;
    font-family: Arial;
    color: #000000;
    margin-top: 50px;
  }

  .wrap .about .time {
    font-size: 16px;
    font-family: PingFang-SC-Regular;
    color: #666666;
    margin-top: 22px;
    font-weight: 500;
  }
  .wrap .about .fd {
    font-size: 14px;
    color: #888888;
    margin-top: 30px;
    padding: 10px;
    line-height: 20px;
  }

  .wrap .about .fd > a {
    font-size: 14px;
    color: #888888;
  }
}
</style>
