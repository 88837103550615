import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import smoothScroll from 'smoothscroll-polyfill'

// 公共样式
import './assets/styles/rest.css'
import './assets/styles/border.css'
import 'lib-flexible/flexible.js'
smoothScroll.polyfill()
createApp(App).use(store).use(router).mount('#app')
